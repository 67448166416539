import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { nanoid } from 'nanoid';
import history from 'store/history';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-tw'; 

import { api } from 'util/api';
import { OnlineRegistrationRecordResource, HospitalClinicResource } from 'util/api/swaggerApi/data-contracts';
import { FormControl } from 'util/formSystem/formControl';
import setupFormModel from 'util/formSystem/setupForm';

import { updateAccessToken } from './auth';

import { GetState, State as GlobalState } from './reducers';
import { ToastStatus, openToast } from './toast';

export interface ExtendedOnlineRegistrationRecordResource extends OnlineRegistrationRecordResource {
	departmentContext: string;
	labelContext: string;
	dateContext: string;
	doctorContext: string;
	timeStamp: number;
}


export interface State {
	loading: boolean;
	records: ExtendedOnlineRegistrationRecordResource[];
	selectedRecord: FormControl<ExtendedOnlineRegistrationRecordResource>;
	selectedRecordClinicDetail: HospitalClinicResource;
}

export const defaultState: State = {
	loading: false,
	records: [],
	selectedRecord: {} as FormControl<ExtendedOnlineRegistrationRecordResource>,
	selectedRecordClinicDetail: {} as HospitalClinicResource,
};

export const formModelConfig = setupFormModel<State>('REGISTRATION_RECORD_FORM', (getState: GetState) => {
	const { recordsRegistration } = getState();
	return recordsRegistration;
});

export const getRegistrationRecords = createAction(
	'GET_REGISTRATION_RECORDS', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1UserOnlineRegistrationRecordList } = api;
			const { departmentRegistration: { clinicsCodeList } } = getState();
			dayjs.locale('zh-tw');
			
			const promises = clinicsCodeList.map(code => 
				v1UserOnlineRegistrationRecordList({ hospital_clinic_code: code })
			);

			const recordsData: ExtendedOnlineRegistrationRecordResource[] = [];
			try {
				const results = await Promise.allSettled(promises);
				results.forEach(result => {
					if (result.status === 'fulfilled') {
						const { data } = result.value.data as { data: OnlineRegistrationRecordResource[] };

						const newData = data?.map((item: any) => {
							const departmentContext = item.department?.replace(/\(.*?\)/, '').trim() || '';
							const doctorContext = `${item.doctor?.replace(/\(.*?\)/, '').trim()}醫師` || '';
							const match = item?.department?.match(/\((.*?)\)/);
							const labelContext = match ? `${match[1]} ${item.vid}` : item.vid;
							
							
							const dateNum = item?.date?.slice(-5);
							const date = dayjs(item.date);
							const weekDay = date.format('(dd)');
							const curDate = item?.date;
							const timeStamp = new Date(curDate).getTime();
							
							return {
								...item,
								departmentContext,
								doctorContext,
								labelContext,
								dateContext: `${dateNum} ${weekDay}`,
								timeStamp
							}
						});
						recordsData.push(...newData as ExtendedOnlineRegistrationRecordResource[]);
					}
				})

			} catch(error) {
				console.log('error', error);
				const { status } = error as { status: number };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
			}

			return recordsData?.sort((a, b) => (a.timeStamp - b.timeStamp))
		}	
);

export const cancelRegistrationRecord = createAction(
	'CANCEL_REGISTRATION_RECORD', () =>
		async (dispatch: Dispatch, getState: GetState) => {
			const { v1UserOnlineRegistrationCancelUpdate } = api;
			const { recordsRegistration: { selectedRecord } } = getState();

			try {
				const { status } = await v1UserOnlineRegistrationCancelUpdate({ hospital_clinic_code: selectedRecord.value.hospitalClinicCode || ''
					, registration_code: selectedRecord.value.registrationCode || ''});

				if (status === 200) {
					history.push({
						pathname: `${history.location.pathname}/cancel`,
					})
				}
			} catch (error1) {
				console.log('cancel error', error1);
				const { status, error } = error1 as { status: number, error: any };
				if (status === 401) {
					dispatch(updateAccessToken(''));
					history.push('/');
				}
				if (status === 400) {
					dispatch(openToast({ toastId: nanoid(), status: ToastStatus.WARNING, text: error.message.message }));
				}
			}
			return null;
		}
);

export const getClinicDetail = createAction(
	'GET_CLINIC_DETAIL', () =>
		async (_: Dispatch, getState: GetState) => {
			const { v1HospitalClinicDetail } = api;
			const { recordsRegistration: { selectedRecord } } = getState();
			const { hospitalClinicCode } = selectedRecord.value;
			try {
				const { data } = await v1HospitalClinicDetail(hospitalClinicCode || '');
				return data?.data;
			} catch (error) {
				console.log('get clinic detail error', error);
				return {};
			}
		}
);
// online-registration/record
export const clearSelectedRecord = createAction('CLEAR_SELECTED_RECORD', () => (dispatch: Dispatch) => {
	dispatch(formModelConfig.actions.setFormCtrlValue('selectedRecord', {}));
});


export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	recordsRegistration: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			...formModelConfig.reducers,
			GET_REGISTRATION_RECORDS_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_REGISTRATION_RECORDS_FULFILLED: (state, action: Action<ExtendedOnlineRegistrationRecordResource[]>) => ({
				...state,
				records: action.payload,
				loading: false,
			}),
			GET_CLINIC_DETAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_CLINIC_DETAIL_FULFILLED: (state, action: Action<HospitalClinicResource>) => ({
				...state,
				loading: false,
				selectedRecordClinicDetail: action.payload,
			}),
		},
		defaultState,
	),
};

const recordsRegistrationActionsMap = {
	...formModelConfig.actions,
	getRegistrationRecords,
	clearSelectedRecord,
	cancelRegistrationRecord,
	getClinicDetail,
};

const mapHooksToState = (state: GlobalState) => ({
	records: state.recordsRegistration.records,
	selectedRecord: state.recordsRegistration.selectedRecord,
	selectedRecordClinicDetail: state.recordsRegistration.selectedRecordClinicDetail,
});

type RecordsSelector = ReturnType<typeof mapHooksToState>;
type RecordsActionsMap = typeof recordsRegistrationActionsMap;

export const useRecordsRegistration = () =>
	useRedux<RecordsSelector, RecordsActionsMap>(
		mapHooksToState,
		recordsRegistrationActionsMap,
	);
