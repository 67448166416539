import * as Sentry from '@sentry/react';

const { SENTRY_DSN } = process.env;

if (SENTRY_DSN) {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [Sentry.replayIntegration()],
		environment: process.env.PROXY,
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}
